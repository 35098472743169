<template>
  <div class="product">
    <div class="xl">
      <div class="productBanner">
        <!--      <div class="left">-->
        <!--        <img class="wow fadeInLeft" src="@/assets/img/product/-2-min.png" alt="" />-->
        <!--      </div>-->
        <!--      <div class="right">-->
        <!--        <p class="title p24">Efficient Way to Improve Your</p>-->
        <!--        <p class="title p24">Management and Boost</p>-->
        <!--        <p class="title p24">YourProductivity</p>-->
        <!--        <div class="btn" @click="toDemo">-->
        <!--          <p class="p22">-->
        <!--            &lt;!&ndash; VIEW DEMO &ndash;&gt;-->
        <!--            <a href="http://demo.tradewisesoftware.com" target="_blank" rel="noopener noreferrer">VIEW DEMO</a>-->
        <!--            </p>-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="header-child">
          <img class="wow fadeInLeft" src="@/assets/img/product/-2-min.png" alt="" />
          <div class="right">
            <div class="const">
              <p  class="title p1" style="color: #fff;">{{$t('home.sys_name')}}</p>
              <p class="title p2">{{$t('home.subTitle9')}}</p>
              <p class="title p3" style="color: #fff;">{{$t('home.subTitle10')}}</p>
              <div class="p4">
                <button @click="goto(0)" class="el-btn">{{$t('system.btn')}}</button>
                <button @click="goto(1)" class="el-btn">{{$t('system.bnt2')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-main highlight">
        <div class="WebMain">
          <div class="title">
            <div class="child">
              <p class="p30">{{$t('home.title4')}}</p>
              <p></p>
            </div>
          </div>
          <div class="main">
            <img class="left" src="@/assets/img/product/web_phone.png">
            <div class="right">
              <div class="item">
                <img class ="icon" src="@/assets/img/product/icon_sign1.svg"/>
                <p class="desc">
                  <span>{{$t('home.desc')}}</span>
                </p>
              </div>
              <div class="item">
                <img class="icon" src="@/assets/img/product/icon_sign2.svg"/>
                <p class="desc"><span>{{$t('home.desc3')}}</span></p>
              </div>
              <div class="item">
                <img class="icon" src="@/assets/img/product/icon_sign3.svg"/>
                <p class="desc"><span>{{$t('home.desc4')}}</span></p>
              </div>
              <div class="item" style="align-items: center">
                <img class="icon" src="@/assets/img/product/icon_sign4.svg"/>
                <p class="desc"><span>{{$t('home.desc5')}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-main ele-card">
        <div class="WebMain">
          <div class="title">
            <div class="child">
              <p class="p30">{{$t('home.title5')}}</p>
              <p></p>
            </div>
          </div>
          <div class="main">
            <img class="left" src="@/assets/img/product/three_phone.png">
            <div class="right">
              <p class="caption">
                {{$t('home.desc6')}}
              </p>
              <div class="item">
                <img class ="icon" src="@/assets/img/product/handle_sth.png"/>
                <div>
                  <p class="sub-title">
                    {{$t('home.subTitle11')}}
                  </p>
                  <p class="desc">{{$t('home.desc7')}}</p>
                </div>
              </div>
              <div class="item">
                <img class="icon" src="@/assets/img/product/icon_label.png"/>
                <div>
                  <p class="sub-title">
                    {{$t('home.subTitle12')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc8')}}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="el-main highlight">
        <div class="WebMain">
          <div class="title">
            <div class="child" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
              <div>
                <p class="p30">{{$t('home.subTitle4')}}</p>
                <p></p>
              </div>
              <div class="p1">{{$t('home.desc9')}}</div>
            </div>
          </div>
          <div class="main">
            <div class="left">
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle13')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc10')}}
                  </p>
                </div>
              </div>
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle14')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc11')}}
                  </p>
                </div>
              </div>
              <img class="bottom-img" src="@/assets/img/product/two_phone.png" alt=""/>
            </div>
            <div class="left">
              <img class="bottom-img" src="@/assets/img/product/more_plat.png" alt=""/>
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle15')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc12')}}
                  </p>
                </div>
              </div>
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle16')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc13')}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-main ele-card" style="margin-bottom: 30px">
        <div class="WebMain">
          <div class="title">
            <div class="child">
              <p class="p30">{{$t('home.subTitle2')}}</p>
              <p></p>
            </div>
          </div>
          <div class="main">

            <div class="left">
              <p class="desc">{{$t('home.desc14')}}</p>
              <div class="app-box" >
                <img class="more-app" src="@/assets/img/product/more_app.png"/>
              </div>
            </div>
            <img class="right-img" src="@/assets/img/product/icon_ppc.png"/>
          </div>
        </div>
      </div>
    </div>
    <div class="xs">
      <div class="productBanner">
        <div class="header-child">
          <img class="wow fadeInLeft" src="@/assets/img/product/-2-min.png" alt="" />
          <div class="right">
            <div class="const">
              <p  class="title p1" style="color: #fff;">{{$t('home.sys_name')}}</p>
              <p class="title p2">{{$t('home.subTitle9')}}</p>
              <p class="title p3" style="color: #fff;">{{$t('home.subTitle10')}}</p>
              <div class="p4">
                <button @click="goto(0)" class="el-btn">{{$t('system.btn')}}</button>
                <button @click="goto(1)" class="el-btn">{{$t('system.bnt2')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-main highlight">
        <div class="WebMain">
          <div class="title">
            <div class="child">
              <p class="p30">{{$t('home.title4')}}</p>
              <p></p>
            </div>
          </div>
          <div class="main">
            <div class="right">
              <div class="item">
                <img class ="icon" src="@/assets/img/product/icon_sign1.svg"/>
                <p class="desc">
                  <span>{{$t('home.desc')}}</span>
                </p>
              </div>
              <div class="item">
                <img class="icon" src="@/assets/img/product/icon_sign2.svg"/>
                <p class="desc"><span>{{$t('home.desc3')}}</span></p>
              </div>
              <div class="item">
                <img class="icon" src="@/assets/img/product/icon_sign3.svg"/>
                <p class="desc"><span>{{$t('home.desc4')}}</span></p>
              </div>
              <div class="item" style="align-items: center">
                <img class="icon" src="@/assets/img/product/icon_sign4.svg"/>
                <p class="desc"><span>{{$t('home.desc5')}}</span></p>
              </div>
            </div>
            <img class="left" src="@/assets/img/product/web_phone.png">
          </div>
        </div>
      </div>
      <div class="el-main ele-card">
        <div class="WebMain">
          <div class="title">
            <div class="child">
              <p class="p30">{{$t('home.title5')}}</p>
              <p></p>
            </div>
          </div>
          <p class="caption">
            {{$t('home.desc6')}}
          </p>
          <div class="main">
            <img class="left" src="@/assets/img/product/three_phone.png">
            <div class="right">

              <div class="item">
                <img class ="icon" src="@/assets/img/product/handle_sth.png"/>
                <div>
                  <p class="sub-title">
                    {{$t('home.subTitle11')}}
                  </p>
                  <p class="desc">{{$t('home.desc7')}}</p>
                </div>
              </div>
              <div class="item">
                <img class="icon" src="@/assets/img/product/icon_label.png"/>
                <div>
                  <p class="sub-title">
                    {{$t('home.subTitle12')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc8')}}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="el-main highlight">
        <div class="WebMain">
          <div class="title">
            <div class="child" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
              <div>
                <p class="p30">{{$t('home.subTitle4')}}</p>
                <p></p>
              </div>
              <div class="p1">{{$t('home.desc9')}}</div>
            </div>
          </div>
          <div class="main">
            <div class="left">
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle13')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc10')}}
                  </p>
                </div>
              </div>
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle14')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc11')}}
                  </p>
                </div>
              </div>
              <img class="bottom-img" src="@/assets/img/product/two_phone.png" alt=""/>
            </div>
            <div class="left">
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle15')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc12')}}
                  </p>
                </div>
              </div>
              <div class="item">
                <p class="diamond"/>
                <div class="left-child">
                  <p class="sub-title">
                    {{$t('home.subTitle16')}}
                  </p>
                  <p class="desc">
                    {{$t('home.desc13')}}
                  </p>
                </div>
              </div>
              <img class="bottom-img" src="@/assets/img/product/more_plat.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div class="el-main ele-card" style="margin-bottom: 30px">
        <div class="WebMain">
          <div class="title">
            <div class="child">
              <p class="p30">{{$t('home.subTitle2')}}</p>
              <p></p>
            </div>
          </div>
          <div class="main">

            <div class="left">
              <p class="desc">{{$t('home.desc14')}}</p>
              <div class="app-box" >
                <img class="more-app" src="@/assets/img/product/more_app.png"/>
              </div>
            </div>
            <img class="right-img" src="@/assets/img/product/icon_ppc.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileDetect from 'mobile-detect';
export default {
  data() {
    return {
      productList: [
        {
          id: 1,
          title: "Intuitive CRM Solution",
          img: "产品-1-min.png",
          text: "Our CRM system can assist in customer relationships management to boost marketing efficiency and improve customer satisfaction. Our system provides a central place where FX brokers can store customer data, track customer profiles and source, and perform KYC verification. You can use our CRM technology and data-driven insights to faster and better understand your customer needs and provide higher quality customer services."
        },
        {
          id: 2,
          title: "Tracking and Motivating IB",
          img: "产品-2-min.png",
          text: "Our CRM system can provide a tracking code and link to your IB, allowing for automated commission calculations and ensuring accurate, visible, and flexible payments. Our data-driven strategies for commissions, rewards, and motivation will incentivize your IB, ultimately leading to increased sales growth.",
        },
        {
          id: 3,
          title: "Effortless Internal Management",
          img: "产品-3-min.png",
          text: "Our CRM system helps FX brokers manage and monitor management department and employees, such as basic accounting, transactions and commissions within a company. Based on those information mentioned above, our system can process data to obtain information that will help you make faster and better-informed decisions. ",
        },
        {
          id: 4,
          title: "Funds Management",
          img: "产品-4-min.png",
          text: "Our CRM system with real time monitoring allows you to be savvier with your deposits, withdrawals, balance, total deposit amount and etc.",
        },
      ],
    };
  },
  methods: {
    goto(e) {
     let device = new MobileDetect(navigator.userAgent),url='';
      if(device.mobile()){
        if(!e) return;
        url='https://mobile.tradewisesoftware.com';
      }else{
        url = e ? 'https://client.tradewisesoftware.com':'https://admin.tradewisesoftware.com';
      }
      window.open(url,'_blank')
    }
  }
};
</script>

<style lang="scss" scoped>

@media screen and (min-width: 992px){
  .xs {
    display: none;
  }
  .xl {

      .header-child {
        width: 66%;
        height: 620px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .fadeInLeft {
          //width: 20%;
          width: 360px;
          height: auto;
          object-fit: contain;
        }
        .right{
          width: 60%;
          display: flex;
          align-items: center;
          .const {
            width: 80%;
            height: auto;
            margin: auto;
            .title {
              text-align: right;
            }
            .p1{
              font-size: 4em;
              font-weight: 900;
              //letter-spacing: 2px;
            }
            .p2{
              margin: 6px 0;
              color: var(--theme-warn-color);
              font-size: 2em;
            }
            .p3 {
              font-size: 20px;
            }
            .p4 {
              text-align: right;
              .el-btn {
                margin-top: 30px;
                padding: 0 16px;
                height: 36px;
                line-height: 8px;
                text-align: center;
                background: var(--custom-color);
                border-radius: 7px;
                border: none;
                cursor: pointer;
                font-family: Oswald-Regular;
                color: #fff;
                font-size: 16px;
              }
              .el-btn:nth-child(1) {
                margin-right: 12px;
              }
            }
          }
        }
      }
      .productBanner {
        background: url("~@/assets/img/product/banner_1.png") no-repeat;
        background-size: 100% 100%;
        //display: flex;
        //align-items: center;
        //flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 36px;

        .left {
          padding:0 80px;
          img {
            height: 590px;
            // width: 62%;
          }
        }

      }
      .el-main {
        //padding-top: 60px;
        //padding-bottom: 151px;
        //padding: 60px 0;
        .WebMain {
          .title {
            display: flex;
            justify-content: center;
            margin-bottom: 28px;

            font-weight: bold;
            p {
              line-height: 26px;
              font-size: 30px;
              &:nth-child(2) {
                margin-top: 15px;
                width: 100px;
                height: 2px;
                background: var(--theme-warn-color);
              }
            }
            .p1 {
              margin-top: 2%;
              font-size: 16px;
            }
          }
          .main {
            width: 100%;
            margin-top: 4%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 28px;

            .item  {
              margin-bottom: 6%;
              display: flex;

              .icon {
                //width: 32px;
                //height: 32px;
                width: 2.4em;
                height: 2.4em;
                margin-right: 12px;
                object-fit: contain;
              }
              .diamond {
                width: 14px;
                height: 14px;
                margin-top: 10px;
                background-color: var(--theme-warn-color);;
                transform: rotate(45deg);
              }
              .left-child {
                flex: 1;
                margin-left: 12px;
              }
              text {
                font-size: 14px;
              }
              .sub-title {
                margin-bottom: 2%;
                font-size: 22px;
              }
              .desc {
                font-size: 16px;
              }
            }
            .left {
              width: 44%;
              height: auto;
              object-fit: contain;
              .bottom-img {
                width: 100%;
                height: auto;
                object-fit: contain;
              }
              .desc {
                font-size: 18px;
                margin-bottom: 6%;
              }
              .app-box {
                padding: 36px;
                background-color: #fff;
                border-radius: 8px;
              }
              .app-box .more-app {
                width: 100%;
                height: auto;
                object-fit: contain;
              }
            }
            .right {
              width: 44%;
              .caption {
                margin-bottom: 6%;
                font-size: 16px;
              }
            }
            .right-img {
              width: 44%;
              height: 26em;
            }
          }
        }
      }

      .explain {
        padding-bottom: 40px;
        // overflow: hidden;
        .WebMain {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            box-sizing: border-box;
            width: 300px;
            height: 540px;
            position: relative;

            &:first-child {
              .all {
                // background-color: #2B2D33;

                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-1-min.png");

                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            &:nth-child(2) {
              .all {
                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-2-min.png");
                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            &:nth-child(3) {
              .all {
                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-3-min.png");
                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            &:nth-child(4) {
              .all {
                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-4-min.png");
                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            .inner {
              padding: 40px;
              // background-color: #2B2D33;
              // padding-top: 356px;
            }
            .all {
              height: 548px;
              width: 100%;
              overflow: hidden;
              position: absolute;
              top: -48px;
              .inner {
                position: absolute;
                top: 548px;
                color: white;
              }
              .num {
                border-left: 2px solid var(--custom-color);
              }
              // background-color: #2B2D33;
              // .inner{
              //   padding-top: 157px;
              // }
            }
            .top {
              padding-top: 356px;
              // display: none;
            }

            .num {
              font-weight: 600;
              //font-family: Arial-Black, Arial;
              padding-left: 25px;

              // border-left: 2px solid #ffffff;
            }
            .title {
              width: 184px;
              font-weight: bold;
              // color: #ffffff;
              line-height: 26px;
              margin-top: 20px;
              margin-bottom: 15px;
            }
            .text {
              //font-family: ArialHebrew;
              // color: #ffffff;
              line-height: 20px;
            }
            &:hover {
              .top {
                opacity: 0;
                transition: all 0.1s;
              }
              .all {
                background-color: #2B2D33;
                transition: all 0.8s;
                // position: absolute;
                // top:-48px;
                background-position: 0 0;
                .inner {
                  transition: all 0.5s;
                  position: absolute;
                  top: 48px;
                  .num{
                    border-left: 2px solid var(--custom-color);
                  }
                }
              }
            }
            // &:first-child{
            //   .top {
            //     opacity: 0;
            //   }
            //   .all {
            //     background-color: #2B2D33;
            //     // position: absolute;
            //     // top:-48px;
            //     background-position: 0 0;
            //     .inner {
            //       position: absolute;
            //       top: 48px;
            //     }
            //   }
            // }
          }
        }
      }

  }
}
@media screen and (max-width: 992px) {
  .xl {
    display: none;
  }
  .xs {
      .header-child {
        width: 92%;
        height: 320px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .fadeInLeft {
          //width: 20%;
          width: 10em;
          height: auto;
          object-fit: contain;
        }
        .right{
          //width: 80%;
          flex: 1;
          display: flex;
          align-items: center;
          .const {
            width: 100%;
            height: auto;
            margin: auto;
            .title {
              text-align: right;
            }
            .p1{
              font-size: 26px;
              font-weight: 900;
              //letter-spacing: 2px;
            }
            .p2{
              margin: 6px 0;
              color: var(--theme-warn-color);
              font-size: 18px;
            }
            .p3 {
              font-size: 12px;
            }
            .p4 {
              text-align: right;
              .el-btn {
                margin-top: 30px;
                padding: 0 10px;
                height: 28px;
                line-height: 8px;
                text-align: center;
                background: var(--custom-color);
                border-radius: 7px;
                border: none;
                cursor: pointer;
                font-family: Oswald-Regular;
                color: #fff;
                font-size: 12px;
              }
              .el-btn:nth-child(1) {
                margin-right: 6px;
              }
            }
          }
        }
      }
      .productBanner {
        background: url("~@/assets/img/product/banner_1.png") no-repeat;
        background-size: 100% 100%;
        //display: flex;
        //align-items: center;
        //flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 36px;

        .left {
          padding:0 80px;
          img {
            height: 590px;
            // width: 62%;
          }
        }

      }
      .el-main {
        padding: 0px;
        //padding-top: 60px;

        .WebMain {
          .title {
            display: flex;
            justify-content: center;
            margin: 28px 0;

            font-weight: bold;
            p {
              line-height: 26px;
              font-size: 18px;
              &:nth-child(2) {
                margin-top: 15px;
                width: 100px;
                height: 2px;
                background: var(--theme-warn-color);
              }
            }
            .p1 {
              margin-top: 2%;
              font-size: 16px;
            }
          }
          .caption {
            margin-bottom: 6%;
            font-size: 14px;
          }
          .main {
            width: 100%;
            margin-top: 4%;
            margin-bottom: 16px;

            .item  {
              margin-bottom: 6%;
              display: flex;

              .icon {
                //width: 32px;
                //height: 32px;
                width: 1.6em;
                height: 1.6em;
                margin-right: 12px;
                object-fit: contain;
              }
              .diamond {
                width: 14px;
                height: 14px;
                margin-top: 6px;
                background-color: var(--theme-warn-color);;
                transform: rotate(45deg);
              }
              .left-child {
                flex: 1;
                margin-left: 12px;
              }
              text {
                font-size: 14px;
              }
              .sub-title {
                margin-bottom: 2%;
                font-size: 16px;
              }
              .desc {
                font-size: 14px;
              }
            }
            .left {
              width: 100%;
              height: auto;
              object-fit: contain;
              .bottom-img {
                width: 100%;
                height: auto;
                object-fit: contain;
              }
              .desc {
                font-size: 14px;
                margin-bottom: 6%;
              }
              .app-box {
                padding: 16px;
                background-color: #fff;
                border-radius: 8px;
              }
              .app-box .more-app {
                width: 100%;
                height: auto;
                object-fit: contain;
              }
            }
            .right {
              width: 100%;

            }
            .right-img {
              width: 100%;
              height: auto;
              margin-top: 6px;
            }
          }
        }
      }
      .explain {
        padding-bottom: 40px;
        // overflow: hidden;
        .WebMain {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            box-sizing: border-box;
            width: 300px;
            height: 540px;
            position: relative;

            &:first-child {
              .all {
                // background-color: #2B2D33;

                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-1-min.png");

                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            &:nth-child(2) {
              .all {
                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-2-min.png");
                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            &:nth-child(3) {
              .all {
                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-3-min.png");
                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            &:nth-child(4) {
              .all {
                background-color: #2B2D33;
                background: url("../../assets/img/product/产品-4-min.png");
                background-size: 100% 100%;
                background-position: 0 548px;
                background-repeat: no-repeat;
              }
            }
            .inner {
              padding: 40px;
              // background-color: #2B2D33;
              // padding-top: 356px;
            }
            .all {
              height: 548px;
              width: 100%;
              overflow: hidden;
              position: absolute;
              top: -48px;
              .inner {
                position: absolute;
                top: 548px;
                color: white;
              }
              .num {
                border-left: 2px solid var(--custom-color);
              }
              // background-color: #2B2D33;
              // .inner{
              //   padding-top: 157px;
              // }
            }
            .top {
              padding-top: 356px;
              // display: none;
            }

            .num {
              font-weight: 600;
              //font-family: Arial-Black, Arial;
              padding-left: 25px;

              // border-left: 2px solid #ffffff;
            }
            .title {
              width: 184px;
              font-weight: bold;
              // color: #ffffff;
              line-height: 26px;
              margin-top: 20px;
              margin-bottom: 15px;
            }
            .text {
              //font-family: ArialHebrew;
              // color: #ffffff;
              line-height: 20px;
            }
            &:hover {
              .top {
                opacity: 0;
                transition: all 0.1s;
              }
              .all {
                background-color: #2B2D33;
                transition: all 0.8s;
                // position: absolute;
                // top:-48px;
                background-position: 0 0;
                .inner {
                  transition: all 0.5s;
                  position: absolute;
                  top: 48px;
                  .num{
                    border-left: 2px solid var(--custom-color);
                  }
                }
              }
            }
            // &:first-child{
            //   .top {
            //     opacity: 0;
            //   }
            //   .all {
            //     background-color: #2B2D33;
            //     // position: absolute;
            //     // top:-48px;
            //     background-position: 0 0;
            //     .inner {
            //       position: absolute;
            //       top: 48px;
            //     }
            //   }
            // }
          }
        }
      }

  }
}
@media screen and (min-width: 800px) and (max-width: 1700px) {
  .product .productBanner .left img {
    //width: 800px;
    height: auto;
  }
  .product .explain .WebMain .item{
    margin-top: 30px;
  }
  
}
@media screen and (min-width: 800px) and (max-width: 1250px){
  .product .el-main .title{
    margin-top: 60px;
  }
  .product .el-main .WebMain .img {
      width: 100%;
      .item1, .item2, .item3{
        position: relative;
        // display: flex;
        width: 100%;
        right: 0;
        top: 0;
        margin-top: 20px;
        .left, .right{
          width: 50%;
        }
        p{
          text-align: center !important;
        }
      }
    }
}
@media screen and (min-width: 1200px) and (max-width: 1919px){
  .product .el-main .title{
    margin-top: 60px;
  }
  .product .el-main .WebMain .img {
    width: 100%;
    .item1, .item2, .item3{
      position: relative;
      // display: flex;
      width: 100%;
      right: 0;
      top: 0;
      margin-top: 20px;
      .left, .right{
        width: 50%;
      }
      p{
        text-align: center !important;
      }
    }
  }
}
@media screen  and (min-width: 1919px){
  .product  .el-main .title{
    margin-top: 80px !important;
  }
  .product .el-main .WebMain .el-main:nth-child(4) {
    margin-bottom: 60px;
  }
  .xl .el-main .WebMain .main .right-img {
    height: 32em;
  }
}
//@media screen and (max-width: 800px) {
//  .product {
//    .productBanner {
//      padding-top: 200px;
//      .left{
//
//      img {
//      width: 100%;
//      height: auto;
//    }
//    }
//    }
//    .el-main .WebMain .img {
//      width: 100%;
//      .item1, .item2, .item3{
//        position: relative;
//        // display: flex;
//        width: 100%;
//        right: 0;
//        top: 0;
//        .left, .right{
//          width: 50%;
//        }
//        p{
//          text-align: center !important;
//        }
//      }
//      // .item1{
//      //   top: 0px;
//      // }
//      // .item2{
//      //   top: 100px;
//      // }
//    }
//     .explain .WebMain{
//      justify-content: center;
//      .item{
//        margin-top: 30px;
//        .top{
//          opacity: 0;
//        }
//        .all{
//          background-position: 0 0 !important;
//          background-color: #2B2D33 !important;
//          .inner{
//            top: 0;
//          }
//        }
//      }
//    }
//  }
//}
</style>